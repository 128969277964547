import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image';

import Layout from '../../components/layout';
import Overview from '../../components/overview';

export default ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Neapolitan Sourdough Pizza</title>
      </Helmet>
      <div>
        <h1>Neapolitan Sourdough Pizza</h1>
        <Img fluid={data.file.childImageSharp.fluid} />
        <Overview>
          This recipe is designed to make 4 pizzas in the smaller size Ooni
          Koda. Getting the temperature right on the oven will take some time,
          if the pizzas are cooking too quickly or burning, turn the oven down
          and wait a few minutes.
        </Overview>
        <h2>Levain</h2>
        <table className="table is-bordered is-hoverable">
          <thead>
            <tr>
              <th>Ingredient</th>
              <th>Weight</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Flour</td>
              <td>50 g</td>
            </tr>
            <tr>
              <td>Water</td>
              <td>50 g</td>
            </tr>
            <tr>
              <td>Active Starter</td>
              <td>20 g</td>
            </tr>
          </tbody>
        </table>
        <h2>Dough</h2>
        <table className="table is-bordered is-hoverable">
          <thead>
            <tr>
              <th>Ingredient</th>
              <th>Bakers %</th>
              <th>Weight</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Flour</td>
              <td>100%</td>
              <td>540 g</td>
            </tr>
            <tr>
              <td>Water</td>
              <td>68%</td>
              <td>348 g</td>
            </tr>
            <tr>
              <td>Levain</td>
              <td>20%</td>
              <td>120 g</td>
            </tr>
            <tr>
              <td>Salt</td>
              <td>2%</td>
              <td>15 g</td>
            </tr>
          </tbody>
        </table>
        <h1>Useful (but not necessary) Tools</h1>
        <ul>
          <li>Stand Mixer</li>
          <li>Bowl Scraper</li>
          <li>Bench Scraper</li>
          <li>Scale (this is pretty necessary)</li>
        </ul>
        <h1>Ingredient Considerations</h1>
        <h2>Flour</h2>
        <p>
          Ideally you want to use a strong, high-protein flour. For many home
          cooks the easiest to find option is King Arthur Bread flour or Caputo
          00. I prefer organic flours from Central Milling such as{' '}
          <strong>High Mountain</strong>. Other good options include King Arthur
          Sir Lancelot and Pendleton Power flour.
        </p>
        <h2>Water</h2>
        <p>
          While it is by no means necessary, I like to use dechlorinated water.
          If you live in a place that treats water with chlorine, simply letting
          it off-gas in an open container overnight will eliminate most of the
          chlorine. Filtration and boiling will also eliminate chlorine more
          rapidly. If your local water is treated with Chloramine, the
          aforementioned methods will not work, but it can be removed with
          Campden Tablets (potassium metabisulfite). Use caution when handling
          potassium metabisulfite and ensure that it is dosed properly. If you
          don't want to go through the hassle of dechlorinating your water, tap
          water works perfectly fine.
        </p>
        <h2>Salt</h2>
        <p>Any non-iodized salt should work fine, no need to get too fancy.</p>
        <h1>Schedule</h1>
        Below is a mock schedule for making the dough, feel free to adjust it to
        your own schedule. Feel free to play around with times and temperatures
        to better suit it to your own needs.
        <h2>Day 1</h2>
        <h3>
          <i>9:00 am</i>
        </h3>
        <p>
          Begin by mixing the ingredients together for the levain. Mix until
          there are no dry spots and everything is well incorporated. Cover and
          place in a warm spot. If it is winter, try in an oven with the light
          on or on top of your fridge. Ferment until doubled in volume and
          bubbly, 4-8 hours.
        </p>
        <h3>
          <i>5:00 pm</i>
        </h3>
        <p>
          Combine the flour, water, and levain in the bowl of a stand mixer. If
          you do not have a stand mixer, mixing by hand works great as well. Mix
          on low speed until there are no dry spots and a shaggy dough is
          formed, 1-2 minutes. Cover with a damp towel and let rest in a warm
          place for 20 minutes.
        </p>
        <h3>
          <i>5:20 pm</i>
        </h3>
        <p>
          Add salt to dough and mix on low speed for 1 minute and medium speed
          for 4-6 minutes until dough is smooth. If kneading by hand, knead for
          at least 10 minutes. Form dough into a rough ball, scrape down the
          bowl, cover with a damp towel, and allow to bulk ferment in a warm
          spot for 4 hours.
        </p>
        <h3>
          <i>9:20 pm</i>
        </h3>
        <p>
          Scrape dough out of the bowl and weigh it. Divide dough into 4 equal
          pieces. Form each piece into a ball by folding all four corners into
          the center, then fold any remaining dough into the center. Pinch to
          seal, flip over so the seam is on the bottom and round the dough out
          on the counter. Place balls into an oiled tray and cover with plastic
          wrap. Refrigerate for 19 hours or up to 2 days.
        </p>
        <h2>Day 2</h2>
        <h3>
          <i>5:00 pm</i>
        </h3>
        <p>Preheat the Ooni Koda at its highest setting for 20 minutes.</p>
        <h3>
          <i>6:00 pm</i>
        </h3>
        <p>
          Dust a pizza peel with flour. Dust your counter generously with flour.
          Remove a dough ball directly from the fridge and place into the flour
          on the counter. Shape the ball into a pizza using a technique like{' '}
          <a href="https://www.youtube.com/watch?v=SjYqw1CLZsA">this</a>. Move
          the stretched dough onto the peel.
        </p>
        <p>
          Top the dough with sauce, cheese, and toppings and slide it into the
          oven. Be prepared to turn the pizza within 15-20 seconds, it will cook
          super fast. Turn as necessary to prevent burning until done.
        </p>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "recipes/neapolitanPizza/recipe.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 300, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
